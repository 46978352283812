import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ChatPrompt from "./Components/ChatPrompt";
import ComingSoon from "./Components/ComingSoon";
import ModelConfigurator from "./Components/ModelConfigurator";
import ChatHistory from "./Components/ChatHistory";
import PrivateRoute from "./Components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import Profile from "./Components/Profile";

const App = () => {
  const [configurations, setConfigurations] = useState({});
  const models = [
    { name: 'openai:gpt-3.5-turbo', description: 'GPT Model by OpenAI',premium:true },
    { name: 'anthropic:claude-v1', description: 'Claude Model by Anthropic',premium:true },
    { name: 'groq:mixtral-8x7b-32768', description: 'Groq AI Mixtral Model',premium:false },
    {"name":"huggingface:mistralai/Mistral-7B-Instruct-v0.3","description":"Hugging Face Mistral Model",premium:false},
    {"name":"fireworks:accounts/fireworks/models/llama-v3p2-3b-instruct","description":"Firework - LLaMa Model",premium:false},
    {"name":"together:meta-llama/Llama-3.2-3B-Instruct-Turbo","description":"Together Llama-3B Model",premium:false},
    // {"name":"cohere:command-r7b-12-2024","description":"Cohere Command r7b Model", premium:false}
  ];

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route
            path="/chat-history"
            element={<PrivateRoute element={<ChatHistory />} />}
          />
          <Route
            path="/chat-prompt"
            element={
              <PrivateRoute
                element={
                  <ChatPrompt
                    configurations={configurations}
                    setConfigurations={setConfigurations}
                    // models={models}
                  />
                }
              />
            }
          />
          <Route
            path="/config"
            element={
              <PrivateRoute
                element={
                  <ModelConfigurator
                    configurations={configurations}
                    setConfigurations={setConfigurations}
                    models={models}
                  />
                }
              />
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
