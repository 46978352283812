import React, { useState, useEffect } from "react";
import { Container, Box, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import ModelDrawer from "./ModelDrawer";
import ConfigurationModal from "./ConfigurationModal";
import Response from "./Response";
import PromptInput from "./PromptInput";
import HeaderActions from "./HeaderActions";
import StripePaymentModal from "./StripePaymentModal";
import { useAuth } from "../contexts/AuthContext";
import { createChatHistory, saveConfiguration } from "../api/api";
import { encryptData } from "../utils/Security";
import { v4 as uuidv4 } from "uuid";
import { Navigate } from "react-router-dom";

const ChatPrompt = ({ configurations, setConfigurations }) => {
  const { user } = useAuth();
  const [prompt, setPrompt] = useState("");
  const [responses, setResponses] = useState([]);
  const [error, setError] = useState("");
  const [totalMsgCount, setTotalMsgCount] = useState(5);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null); // Selected model
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchedModels, setFetchedModels] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(true);


  const suggestedTopics = [
    "Healthcare in the United States",
    "Political Corruption: Insider Trading",
    "Billionaires in Government",
    "The Spread of Misinformation via Social Media",
    "Youth and Money Management",
    "Mental Health: Breaking the Stigma",
  ];

  const handleSaveConfig = async (modelName, config) => {
    try {
      await saveConfiguration(modelName, config); // Save to backend
      setConfigurations((prev) => ({ ...prev, [modelName]: config })); // Update state with the new configuration
      setSelectedModel(modelName); // Ensure the model is marked as selected
      alert("Configuration saved successfully."); // Feedback to the user
    } catch (err) {
      console.error("Error saving configuration:", err);
      setError("Failed to save the configuration.");
    }
  };


  const fetchConfigurations = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/config/get_all_configurations`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const fetchedConfigurations = response.data.reduce((acc, config) => {
        acc[config.model_name] = config.configuration;
        return acc;
      }, {});

      // Save to local storage for persistence
      localStorage.setItem("modelConfigurations", JSON.stringify(fetchedConfigurations));

      setConfigurations(fetchedConfigurations); // Update the state
    } catch (err) {
      console.error("Error fetching configurations:", err);
      setError("Failed to load model configurations.");
    }
  };

  useEffect(() => {
    const initializeConfigurations = async () => {
      try {
        const savedConfigurations = JSON.parse(localStorage.getItem("modelConfigurations"));
        if (savedConfigurations) {
          setConfigurations(savedConfigurations);
          setSelectedModel(Object.keys(savedConfigurations)[0]); // Default to the first configured model
        } else {
          await fetchConfigurations();
        }
      } catch (err) {
        console.error("Error initializing configurations:", err);
      }
    };

    initializeConfigurations();
  }, []);



  const fetchUserInfo = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User is not authenticated.");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user_info/`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserInfo(response.data);
      setFetchedModels(response.data.models);

      // Automatically select a model if not already selected
      const defaultModel = response.data.models.find((model) => model.active);
      if (defaultModel) setSelectedModel(defaultModel.name);
    } catch (err) {
      console.error("Failed to fetch user info:", err);
      setError("Failed to fetch user info.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserInfo();
      await fetchConfigurations();
    };
    fetchData();
  }, []);

  const handlePaymentSuccess = async () => {
    setIsPaymentModalOpen(false);
    try {
      await fetchUserInfo();
    } catch (err) {
      console.error("Error fetching user info after payment:", err);
      setError("Failed to update user info after payment.");
    }
  };
  const handleSubmit = async () => {
    if (!prompt.trim()) {
      setError("Please enter a prompt.");
      return;
    }

    if (!selectedModel) {
      setError("No model is selected. Please configure one to start the conversation.");
      return;
    }

    const modelDetails = fetchedModels.find((model) => model.name === selectedModel);
    if (!modelDetails) {
      setError("Selected model not found.");
      return;
    }

    if (modelDetails.premium && !modelDetails.active) {
      setError(`You don't have access to the ${selectedModel} model. Please purchase it first.`);
      handlePayment(selectedModel);
      return;
    }

    // Fetch updated configurations to ensure they are up to date
    const currentConfig = configurations[selectedModel];
    if (!currentConfig) {
      setError(`The selected model (${selectedModel}) is not configured. Please configure it to proceed.`);
      setIsModalOpen(true); // Open the configuration modal
      return;
    }

    setError("");
    setIsProcessing(true);

    try {
      let currentPrompt = prompt;
      let accumulatedResponses = [...responses]; // Keep track of all generated responses

      // Loop to limit the number of messages sent
      for (let i = 0; i < totalMsgCount; i++) {
        // Filter out only the configured models
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/initiate`,
          {
            prompt: currentPrompt,
            totalMsgCount: totalMsgCount,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const newResponses = response.data.responses || [];
        accumulatedResponses = [...accumulatedResponses, ...newResponses];
        setResponses(accumulatedResponses);  // Update the response state

        // Update the prompt with the last response's content for the next model
        const lastResponse = newResponses?.[0]?.response;
        if (!lastResponse) break;

        currentPrompt = lastResponse;

        // Delay before the next message
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // Scroll to the latest response
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      }

      setPrompt(""); // Clear the input prompt
    } catch (err) {
      console.error("Error submitting prompt:", err);
      setError("Failed to process the prompt. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };


  // useEffect(() => {
  //   console.log("Configurations:", configurations);
  //   console.log("Selected Model:", selectedModel);
  // }, [configurations, selectedModel]);


  const handlePayment = (modelName) => {
    const selectedModel = fetchedModels.find((model) => model.name === modelName);

    if (!selectedModel) {
      alert("Model not found.");
      return;
    }

    if (!selectedModel.premium || selectedModel.active) {
      setSelectedModel(modelName);
      setIsModalOpen(true);
      return;
    }

    setSelectedModel(modelName);
    setIsPaymentModalOpen(true);
  };

  const saveChatHistory = () => {
    const obj = {
      [prompt + "##" + new Date().toISOString()]: responses,
    };
    const key = "aiOske5fS_2361c" + uuidv4().toString();
    const encryptedData = encryptData(JSON.stringify(obj));
    localStorage.setItem(key, encryptedData);

    if (user.premium) {
      createChatHistory({ content: encryptedData });
    }
  };

  if (loading) {
    return <CircularProgress size={50} sx={{ position: "absolute", top: "50%", left: "50%" }} />;
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return (
    <Container component="main" maxWidth="lg">
      <ConfigurationModal
        open={isModalOpen}
        modelName={selectedModel}
        config={configurations[selectedModel] || { tone: "neutral", imitate: "", formality: "medium" }}
        onClose={() => setIsModalOpen(false)}
        onSave={(modelName, config) => handleSaveConfig(modelName, config)}
      />

      <StripePaymentModal
        open={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        modelName={selectedModel}
        userId={user?.id}
        onPaymentSuccess={handlePaymentSuccess}
      />

      <HeaderActions
  handleClearChat={() => {
    setResponses([]);
    // setIsDrawerVisible(true);

  }}
  saveChatHistory={saveChatHistory}
/>

<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
  <div
    onMouseEnter={() => setIsDrawerVisible(true)} // Show the drawer when hovered
    onMouseLeave={() => {
      if (!isPaymentModalOpen && !document.querySelector(".MuiModal-root")) {
        setIsDrawerVisible(false); // Hide the drawer only if no modal is open
      }
    }}
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      height: "100%",
      width: isDrawerVisible ? "300px" : "0", // Adjust width dynamically (hide completely)
      backgroundColor: isDrawerVisible ? "#283593" : "transparent", // Transition background
      transition: "width 0.3s ease-in-out, background-color 0.3s ease-in-out", // Smooth transition for width and background
      overflow: "hidden",
      zIndex: 1000, // Ensure it appears above other elements
    }}
  >


    {isDrawerVisible && (
      <ModelDrawer
        models={fetchedModels}
        onConfigure={(modelName) => {
          setSelectedModel(modelName);
          setIsModalOpen(true);
        }}
        configurations={configurations}
        userInfo={userInfo}
      />
    )}
  </div>
        {/* Indicator for Drawer */}
        {!isDrawerVisible && (
        <div
          onMouseEnter={() => setIsDrawerVisible(true)} // Show drawer when hovering over the indicator
          style={{
            position: "absolute",
            top: "50%",
            left: 0,
            transform: "translateY(-50%)",
            backgroundColor: "#3949AB",
            color: "#FFFFFF",
            width: "40px",
            height: "100px",
            borderRadius: "0 5px 5px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            zIndex: 999,
          }}
        >
          <span style={{ writingMode: "vertical-rl", textAlign: "center" }}>Models</span>
        </div>

      )}

  {/* Payment Modal */}
  <StripePaymentModal
    open={isPaymentModalOpen}
    onClose={() => {
      setIsPaymentModalOpen(false); // Close the payment modal
      setIsDrawerVisible(true); // Ensure the drawer stays visible after payment closes
    }}
    modelName={selectedModel}
    userId={user?.id}
  />

  <PromptInput
    totalMsgCount={totalMsgCount}
    setTotalMsgCount={setTotalMsgCount}
    prompt={prompt}
    setPrompt={setPrompt}
    suggestedTopics={suggestedTopics}
    handleSubmit={handleSubmit}
    isProcessing={isProcessing}
    error={error}
  />

  <Box sx={{ borderRadius: 2, p: 2 }}>
    <Response responses={responses} />
    {isProcessing && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <CircularProgress size={24} />
      </Box>
    )}
  </Box>
</motion.div>

    </Container>
  );
};

export default ChatPrompt;
