import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:5000';
// console.log('API_URL:', API_URL);

// Hash password before sending it to the server
const hashPassword = (password) => CryptoJS.SHA256(password).toString();

export const notify = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/api/notify`, { email });
    return response.data;
  } catch (error) {
    console.error('Notification failed:', error.response?.data?.message);
    throw new Error(error.response?.data?.message || 'Notification failed');
  }
};

export const isLoggedIn = async (email) => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      return false;
    }

    const response = await axios.post(
      `${API_URL}/auth/isLoggedIn`,
      { email },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return response.data.message === 'Authenticated';
  } catch (error) {
    console.error('Error logging in:', error.response?.data.message);
    throw error; // Let the component handle navigation
  }
};

export const loginUser = async (email, password) => {
  try {
    const hashedPassword = hashPassword(password);
    const response = await axios.post(`${API_URL}/auth/login`, { email, password: hashedPassword });

    localStorage.setItem("token", response.data.token);
    localStorage.setItem("user_info", JSON.stringify(response.data));
    // console.log("Login Status:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error.response?.data);
    throw error;
  }
};

export const logoutUser = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/auth/logout`, {}, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user_info");
    return true;
  } catch (error) {
    console.error("Error logging out:", error.response?.data);
    throw error;
  }
};

export const registerUser = async (email, password) => {
  try {
      const hashedPassword = hashPassword(password);
      const response = await axios.post(
          `${API_URL}/auth/register`,
          { email, password: hashedPassword },
          { timeout: 5000 }
      );
      return response.data;
  } catch (error) {
      console.error('Request failed:', error.message);
      throw error;
  }
};



export const getChatHistory = async () => {
  try {
    const response = await axios.get(API_URL+'/chat_history', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat history:', error);
    return null;
  }
};

export const createChatHistory = async ({content}) => {
  try {


    const response = await axios.post(API_URL+'/chat_history', {key:process.env.REACT_APP_ENC_KEY,content}, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating chat history:', error);
    return null;
  }
};

export const updateChatHistory = async (id, data) => {
  try {

    const response = await axios.put(`${API_URL+'/chat_history'}/${id}`, data, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating chat history:', error);
    return null;
  }
};

export const deleteChatHistory = async (id) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(`${API_URL+'/chat_history'}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting chat history:', error);
    return null;
  }
};

export const saveConfiguration = async (modelId, configuration) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/config/save_configuration`,
      { model_id: modelId, configuration }, // Use model_id
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log(response.data.message);
  } catch (err) {
    console.error("Error saving configuration:", err);
    throw err;
  }
};

export const fetchModels = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/models/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data; // Return the data
  } catch (err) {
    console.error("Failed to fetch models:", err);
    throw err; // Propagate error to handle in the calling function
  }
};
