import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import HeaderActions from "./HeaderActions";

const Profile = ({ handleClearChat, saveChatHistory }) => {
  const [userData, setUserData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [updating, setUpdating] = useState(false);

  // Redirect to login if user is not authenticated
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      window.location.href = "/login";
    }
  }, []);

  // Fetch user profile and purchases
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const [profileResponse, purchasesResponse] = await Promise.allSettled([
          axios.get(`${process.env.REACT_APP_API_URL}/profile/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/purchases/`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        if (profileResponse.status === "fulfilled") {
          setUserData(profileResponse.value.data);
          setOriginalData(profileResponse.value.data);
        } else {
          console.error("Error fetching profile:", profileResponse.reason);
        }

        if (purchasesResponse.status === "fulfilled") {
          // console.log(purchasesResponse.value.data);
          setPurchases(purchasesResponse.value.data);
        } else {
          console.error("Error fetching purchases:", purchasesResponse.reason);
          setError("Failed to fetch purchases.");
        }
      } catch (err) {
        console.error("Unexpected error:", err);
        setError("Failed to fetch profile or purchases.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCancelSubscription = async (purchase) => {
      try {
        const token = localStorage.getItem("token");
        if (!window.confirm("Are you sure you want to cancel this subscription?")) {
          return;
        } // Confirm before processing refund
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/paywall/r/`,
          { purchase_id: purchase.id },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        alert(response.data.message);
        // Refresh purchases after successful refund
        const updatedPurchases = purchases.filter((p) => p.id !== purchase.id);
        setPurchases(updatedPurchases);
      } catch (err) {
        console.error("Error processing refund:", err);
        alert("Failed to process refund. Please try again.");
      }
    };

  const handleSave = async () => {
    if (JSON.stringify(userData) === JSON.stringify(originalData)) {
      setError("No changes to save.");
      return;
    }

    setUpdating(true);
    setError("");
    setSuccess("");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/profile/`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setOriginalData(userData); // Update original data after successful save
      setSuccess("Profile updated successfully!");
    } catch (err) {
      console.error("Error updating profile:", err);
      setError("Failed to update profile. Please try again.");
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />;
  }

  return (
    <Container component="main" maxWidth="lg">
      {/* Header Actions */}
      <HeaderActions handleClearChat={handleClearChat} saveChatHistory={saveChatHistory} />

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        {/* Profile Section */}
        <Box
          sx={{
            mt: 4,
            p: 3,
            boxShadow: 2,
            borderRadius: 2,
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h4" mb={2}>
            My Profile
          </Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
          <TextField
            label="First Name"
            name="first_name"
            value={userData.first_name || ""}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Last Name"
            name="last_name"
            value={userData.last_name || ""}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone Number"
            name="phone_number"
            value={userData.phone_number || ""}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Email"
            name="email"
            value={userData.email || ""}
            fullWidth
            disabled
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={updating}
            fullWidth
          >
            {updating ? "Saving..." : "Save Changes"}
          </Button>
        </Box>

        {/* Subscriptions Section */}
        <Box
          sx={{
            mt: 4,
            p: 3,
            boxShadow: 2,
            borderRadius: 2,
            backgroundColor: "#fff",
          }}
        >
          <Typography variant="h4" mb={2}>
            My Subscriptions
          </Typography>
          {purchases.length === 0 ? (
            <Typography variant="body1">No subscriptions found.</Typography>
          ) : (
            <List>
              {purchases.map((purchase) => (
                <ListItem key={purchase.id} sx={{ mb: 2, borderBottom: "1px solid #eee" }}>
  <ListItemText
    primary={`Model: ${purchase.model_name}`}
    secondary={`Valid Till: ${new Date(purchase.valid_till).toLocaleDateString()}`}
  />
<Button
  variant="outlined"
  color="secondary"
  onClick={() => handleCancelSubscription(purchase)}
  sx={{ ml: 2 }}
>
  Cancel Subscription
</Button>

</ListItem>

              ))}
            </List>
          )}
        </Box>
      </motion.div>
    </Container>
  );
};

export default Profile;