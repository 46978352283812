import React, { useState } from 'react';
import {
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { motion } from 'framer-motion';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const PromptInput = ({
  prompt,
  setPrompt,
  handleSubmit,
  isProcessing,
  error,
  suggestedTopics,
  totalMsgCount,
  setTotalMsgCount,
}) => {
  const [options, setOptions] = useState(suggestedTopics);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPrompt(value);
    setOptions(value ? [value, ...suggestedTopics] : suggestedTopics);
  };

  const handleIncreaseCount = () => {
    if (totalMsgCount < 10) {
      setTotalMsgCount(totalMsgCount + 1);
    }
  };

  const handleDecreaseCount = () => {
    if (totalMsgCount > 1) {
      setTotalMsgCount(totalMsgCount - 1);
    }
  };

  return (
    <Stack spacing={4} sx={{ mt: 6, maxWidth: '900px', mx: 'auto' }}>
      <motion.div
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <Box
          sx={{
            textAlign: 'center',
            background: 'linear-gradient(145deg, #ffffff, #f1f1f1)',
            borderRadius: '16px',
            p: 5,
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              component="h1"
              variant="h4"
              gutterBottom
              sx={{
                color: '#3f51b5',
                fontWeight: 'bold',
                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
              }}
            >
              Let Your Thoughts Flow
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                color: '#666',
                mb: 3,
                textShadow: '1px 1px 1px rgba(0, 0, 0, 0.05)',
              }}
            >
              Share a topic or select from our suggestions below.
            </Typography>

            {/* Topic of Discussion Input */}
            <Autocomplete
              value={prompt}
              onChange={(e, value) => setPrompt(value)}
              options={options || []}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  margin="normal"
                  required
                  label="Topic of Discussion"
                  placeholder="e.g., Future of AI"
                  autoFocus
                  disabled={isProcessing}
                  sx={{
                    width: '100%', // Full width
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                  onChange={handleInputChange}
                />
              )}
            />

            {/* Total Messages Stepper */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
              }}
            >
              <TextField
                type="number"
                value={totalMsgCount}
                variant="outlined"
                margin="normal"
                required
                disabled={isProcessing}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={handleDecreaseCount}
                        disabled={totalMsgCount <= 1 || isProcessing}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleIncreaseCount}
                        disabled={totalMsgCount >= 10 || isProcessing}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: { min: 1, max: 10, style: { textAlign: 'center' } },
                }}
                label="Total Messages"
                sx={{
                  width: '300px', // Fixed width for better alignment
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>

            {/* Submit Button */}
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{
                mt: 4,
                py: 1.5,
                fontSize: '1rem',
                fontWeight: 'bold',
                background: 'linear-gradient(90deg, #3f51b5, #5a69d4)',
                borderRadius: '8px',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s',
                '&:hover': {
                  background: 'linear-gradient(90deg, #5a69d4, #3f51b5)',
                  transform: 'scale(1.03)',
                },
              }}
              onClick={handleSubmit}
              disabled={isProcessing}
            >
              {isProcessing ? (
                'Stop'
              ) : (
                'Submit'
              )}
            </Button>

            {error && (
              <Typography
                color="error"
                sx={{
                  mt: 2,
                  fontWeight: 'bold',
                  fontSize: '0.95rem',
                }}
              >
                {error}
              </Typography>
            )}
          </motion.div>
        </Box>
      </motion.div>
    </Stack>
  );
};

export default PromptInput;
