import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import ModelDrawer from "./ModelDrawer";
import ConfigurationModal from "./ConfigurationModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ModelConfigurator = ({
  configurations,
  setConfigurations,
  models,
  setError, // If passed as a prop
  userInfo, // If passed as a prop
}) => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch configurations from the database on mount
  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_configurations`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const fetchedConfigurations = response.data.reduce((acc, config) => {
          acc[config.model_name] = config.configuration;
          return acc;
        }, {});
        setConfigurations(fetchedConfigurations);
      } catch (err) {
        console.error("Error fetching configurations:", err);
        setError && setError("Failed to load model configurations.");
      }
    };

    fetchConfigurations();
  }, [models, setConfigurations, setError]);

  const handleConfigure = (modelName) => {
    if (!modelName) {
      setError && setError("No model is selected. Please configure a model to proceed.");
      return;
    }

    if (configurations[modelName]) {
      setError && setError(`The model ${modelName} is already configured. You can reconfigure it.`);
      setSelectedModel(modelName);
      setIsModalOpen(true);
      return;
    }

    const selectedModel = models.find((model) => model.name === modelName);
    if (selectedModel?.premium && !userInfo?.models.some((m) => m.name === modelName)) {
      setError && setError(`${modelName} requires payment. Please purchase it first.`);
      openPaymentModal(modelName);
      return;
    }

    setError && setError(""); // Clear errors
    setSelectedModel(modelName);
    setIsModalOpen(true);
  };


  const handleSaveConfig = async (modelName, config) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/save_configuration`,
        { model_name: modelName, configuration: config },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedConfigurations = {
        ...configurations,
        [modelName]: config,
      };
      setConfigurations(updatedConfigurations);
      console.log("Configuration saved to DB:", response.data.message);
    } catch (err) {
      console.error("Error saving configuration:", err);
      setError && setError("Failed to save the configuration.");
    }
  };

  const openPaymentModal = (modelName) => {
    console.log(`Opening payment modal for: ${modelName}`);
    // Payment modal logic here
  };

  return (
    <div style={{ display: "flex" }}>
      <ModelDrawer
        models={models}
        onConfigure={handleConfigure}
        configurations={configurations}
        userInfo={userInfo}
      />

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4 }}
        onClick={() => navigate(-1)}
      >
        Back to Chat
      </Button>

      <ConfigurationModal
        open={isModalOpen}
        modelName={selectedModel}
        config={configurations[selectedModel] || { tone: "neutral", imitate: "", formality: "medium" }}
        onClose={() => {
          setIsModalOpen(false);
          if (!configurations[selectedModel]) {
            setSelectedModel(null);
            setError("Configuration is required for this model.");
          }
        }}
        onSave={(modelName, config) => {
          console.log("Saving Configuration:", modelName, config);
          handleSaveConfig(modelName, config);
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};

export default ModelConfigurator;
