import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  CssBaseline,
  Paper,
  Alert,
  Grid,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "../styles/common.css";
import { useAuth } from "../contexts/AuthContext";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { registerUser } from "../api/api";

const features = [
  "Dynamic Multi-AI Conversations",
  "AI Experts with Unique Perspectives",
  "Topic-Driven Discussions",
  "Real-Time Insights and Reactions",
  "Interactive and Engaging Dialogue",
  "24/7 AI Availability for Ongoing Discussions",
];

const ComingSoon = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegister, setIsRegister] = useState(false); // Toggle between login and register
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/chat-prompt");
    }
  }, []);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);



  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleLogin = async () => {
    if (!validateEmail(email) || !password) {
      setMessage("Please enter a valid email and password.");
      setMessageType("error");
      return;
    }

    try {
      await login({ email, password });
      setMessage("Login successful!");
      setMessageType("success");
      navigate("/chat-prompt");
    } catch (error) {
      setMessage("Login failed. Please check your credentials.");
      setMessageType("error");
    }
  };

  const handleRegister = async () => {
    if (!validateEmail(email)) {
      setMessage("Please enter a valid email.");
      setMessageType("error");
      return;
    }
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      setMessageType("error");
      return;
    }

    try {
      // Simulate register API call
      await registerUser(email, password)
      setMessage("Registration successful! You can now log in.");
      setMessageType("success");
      setIsRegister(false);
    } catch (error) {
      setMessage("Registration failed. Please try again.");
      setMessageType("error");
    }
  };

  const handleLogout = () => {
    logout();
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setMessage("You have been logged out.");
    setMessageType("info");
  };

  return (
    <Container maxWidth="lg" sx={{ minHeight: "100vh", position: "relative" }}>
      <CssBaseline />

      {/* Header */}
{/* Header */}
<Box
  sx={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    py: 2,
    px: 3,
    background: "linear-gradient(90deg, #3f51b5, #5a69d4)",
    color: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
    mb: 4,
  }}
>
  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
    AI CHAT JAM
  </Typography>
  {isAuthenticated && (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleLogout}
        sx={{ fontWeight: "bold", backgroundColor: "#ff5252" }}
      >
        Logout
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/profile")}
        sx={{ fontWeight: "bold" }}
      >
        Profile
      </Button>
    </Box>
  )}
</Box>


      {/* Main Content */}
      <Grid container spacing={4}>
        {/* Left Section: Features */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={10}
            sx={{
              p: 4,
              borderRadius: "16px",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#f9f9f9",
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <RocketLaunchIcon
                sx={{
                  fontSize: "3rem",
                  color: "#3f51b5",
                  mb: 2,
                  animation: "spin 3s linear infinite",
                }}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  color: "#3f51b5",
                  mb: 2,
                  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              >
                Why You'll Love AI Chat Jam
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "flex-start",
                }}
              >
                {features.map((feature, index) => (
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{
                      fontSize: "1.1rem",
                      color: "#555",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    • {feature}
                  </Typography>
                ))}
              </Box>
            </motion.div>
          </Paper>
        </Grid>

        {/* Right Section: Login/Register */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={10}
            sx={{
              p: 4,
              borderRadius: "16px",
              textAlign: "center",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  color: "#3f51b5",
                  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
              >
                {isRegister ? "Create Your Account" : "Welcome Back!"}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: "#666", mb: 3, fontStyle: "italic" }}
              >
                {isRegister
                  ? "Sign up to start your AI-powered conversations."
                  : "Please login to continue your AI-powered conversations."}
              </Typography>

              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={{
                  mb: 2,
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                }}
              />
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                sx={{
                  mb: 2,
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                }}
              />
              {isRegister && (
                <TextField
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  fullWidth
                  sx={{
                    mb: 2,
                    backgroundColor: "#f9f9f9",
                    borderRadius: "8px",
                  }}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={isRegister ? handleRegister : handleLogin}
                fullWidth
                sx={{
                  py: 1.5,
                  fontSize: "1rem",
                  fontWeight: "bold",
                  borderRadius: "8px",
                  background: "linear-gradient(90deg, #3f51b5, #5a69d4)",
                  "&:hover": {
                    background: "linear-gradient(90deg, #5a69d4, #3f51b5)",
                  },
                }}
              >
                {isRegister ? "Register" : "Log In"}
              </Button>

              <Typography
                variant="body2"
                sx={{
                  mt: 3,
                  color: "#666",
                  cursor: "pointer",
                  transition: "color 0.3s, transform 0.3s",
                  "&:hover": {
                    color: "#3f51b5",
                    transform: "scale(1.05)"
                  },
                  textDecoration: "underline",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                onClick={() => setIsRegister(!isRegister)}
              >
                {isRegister
                  ? "Already have an account? Log In"
                  : "Don't have an account? Register"}
              </Typography>


              {message && (
                <Alert severity={messageType} sx={{ mt: 3 }}>
                  {message}
                </Alert>
              )}
            </motion.div>
          </Paper>
        </Grid>
      </Grid>

      {/* Footer */}
      <Typography
        variant="caption"
        sx={{
          textAlign: "center",
          display: "block",
          mt: 4,
          color: "#666",
        }}
      >
        Product of{" "}
        <a
          href="https://beatior.net"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#3f51b5", fontWeight: "bold" }}
        >
          Beatior Inc.
        </a>
      </Typography>
    </Container>
  );
};

export default ComingSoon;
