import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Slider, Select, MenuItem, Button, TextField, FormControl } from "@mui/material";

const ConfigurationModal = ({ open, modelName, config, onClose, onSave }) => {
  const [localConfig, setLocalConfig] = useState({
    tone: 50,
    formality: 50,
    imitate: "Normal Person",
    ...config,
  });
  const [customCelebrity, setCustomCelebrity] = useState(config?.imitate === "custom" ? config.customCelebrity : "");

  useEffect(() => {
    setLocalConfig({
      tone: 50,
      formality: 50,
      imitate: "Normal Person",
      ...config,
    });
    setCustomCelebrity(config?.imitate === "custom" ? config.customCelebrity : "");
  }, [config]);

  const handleChange = (field, value) => {
    setLocalConfig((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleCustomCelebrityChange = (value) => {
    setCustomCelebrity(value);
    setLocalConfig((prev) => ({
      ...prev,
      imitate: "custom",
      customCelebrity: value,
    }));
  };

  const handleSave = () => {
    const finalConfig = { ...localConfig };
    if (localConfig.imitate === "custom") {
      finalConfig.imitate = customCelebrity;
    }
    onSave(modelName, finalConfig);
    onClose();
  };

  const sliderMarks = {
    tone: [
      { value: 0, label: "Aggressive" },
      { value: 50, label: "Default" },
      { value: 100, label: "Sweet" },
    ],
    formality: [
      { value: 0, label: "Very Casual" },
      { value: 50, label: "Default" },
      { value: 100, label: "Very Formal" },
    ],
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 600 },
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ textAlign: "center", mb: 3 }}>
          Configure Model: {modelName}
        </Typography>

        <Box sx={{ mb: 3, ml: 3, mr: 3 }}>
          <Typography gutterBottom>Tone</Typography>
          <Slider
            value={Number(localConfig.tone) || 50}
            onChange={(e, value) => handleChange("tone", value)}
            aria-labelledby="tone-slider"
            min={0}
            max={100}
            step={1}
            valueLabelDisplay="auto"
            marks={sliderMarks.tone}
          />

          <Typography gutterBottom>Formality</Typography>
          <Slider
            value={Number(localConfig.formality) || 50}
            onChange={(e, value) => handleChange("formality", value)}
            aria-labelledby="formality-slider"
            min={0}
            max={100}
            step={1}
            valueLabelDisplay="auto"
            marks={sliderMarks.formality}
          />

          <Typography gutterBottom>Imitate Celebrity</Typography>
          <FormControl fullWidth>
            <Select
              value={localConfig.imitate === "custom" ? "custom" : localConfig.imitate}
              onChange={(e) => handleChange("imitate", e.target.value)}
            >
              <MenuItem value="Normal Person">Normal Person</MenuItem>
              <MenuItem value="Joker from Batman">Joker from Batman</MenuItem>
              <MenuItem value="Walter White from Breaking Bad">Walter White from Breaking Bad</MenuItem>
              <MenuItem value="custom">Custom...</MenuItem>
            </Select>
          </FormControl>

          {localConfig.imitate === "custom" && (
            <TextField
              label="Custom Celebrity"
              fullWidth
              margin="normal"
              value={customCelebrity}
              onChange={(e) => handleCustomCelebrityChange(e.target.value)}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={onClose} sx={{ borderRadius: 2 }} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave} sx={{ borderRadius: 2 }}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfigurationModal;
