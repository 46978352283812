import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TuneIcon from "@mui/icons-material/Tune";
import { motion } from "framer-motion";
import { useAuth } from "../contexts/AuthContext";
import StripePaymentModal from "./StripePaymentModal";

const ModelDrawer = ({ models, onConfigure, configurations, userInfo }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [modelName, setModelName] = useState("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(true); // Default state
  const { user } = useAuth();

  // Retrieve the drawer visibility state from localStorage when the component mounts
  useEffect(() => {
    const storedDrawerState = localStorage.getItem("isDrawerVisible");
    if (storedDrawerState !== null) {
      setIsDrawerVisible(JSON.parse(storedDrawerState)); // Use the stored value
    }
  }, []);

  // Save the drawer visibility state to localStorage when it changes
  useEffect(() => {
    localStorage.setItem("isDrawerVisible", JSON.stringify(isDrawerVisible));
  }, [isDrawerVisible]);

  const toggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  const openPaymentModal = (modelName) => {
    setModelName(modelName);
    setIsPaymentModalOpen(true);
  };

  const closePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const drawerContent = (
    <>
      <Typography
        variant="h5"
        component="div"
        sx={{
          m: 3,
          textAlign: "center",
          color: "#FFFFFF",
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
        }}
      >
        AI Models
      </Typography>
      <Divider sx={{ bgcolor: "#4CAF50", mx: 3 }} />

      <List>
        {models.map((model) => {
          const isConfigured = configurations[model.name];
          const isPremium = model.premium;
          const isPaid = model.active && model.status !== "canceled"; // Check for active and not canceled

          let secondaryText = "Unconfigured";
          let secondaryTextColor = "#C5CAE9";

          if (isPremium) {
            if (isPaid) {
              // Premium and Paid
              if (isConfigured) {
                secondaryText = "Configured";
                secondaryTextColor = "#4CAF50";
              } else {
                secondaryText = "Paid but Unconfigured";
                secondaryTextColor = "black";
              }
            } else {
              // Premium but Not Paid
              secondaryText = "Requires Payment";
              secondaryTextColor = "#FF5252";
            }
          } else {
            // Free Models
            if (isConfigured) {
              secondaryText = "Configured";
              secondaryTextColor = "#4CAF50";
            } else {
              secondaryText = "Configure to Use";
              secondaryTextColor = "#C5CAE9";
            }
          }

          return (
            <motion.div
              key={model.name}
              whileHover={{
                scale: 1.03,
                boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
              }}
              style={{ marginBottom: "10px" }}
            >
              <ListItem
                button="true"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 2,
                  borderRadius: 2,
                  transition: "width 0.3s ease-in-out, background-color 0.3s ease-in-out", // Smooth transition
                  bgcolor: isPremium ? "#FFD700" : "#3949AB",
                  color: isPremium ? "black" : "white",
                  "&:hover": {
                    bgcolor: isPremium ? "#FFC107" : "#283593",
                  },
                }}
              >
                <ListAltIcon
                  sx={{
                    color: isPremium ? "black" : "#C5CAE9",
                    fontSize: 30,
                    mr: 2,
                  }}
                />

                <ListItemText
                  primary={model.description}
                  secondary={
                    <Typography
                      variant="body2"
                      sx={{
                        color: secondaryTextColor,
                        fontStyle: "italic",
                      }}
                    >
                      {secondaryText}
                    </Typography>
                  }
                />

                <Tooltip
                  title={
                    !isPremium || isPaid
                      ? isConfigured
                        ? "Reconfigure Model"
                        : "Configure Model"
                      : "Upgrade to Premium"
                  }
                >
                  <IconButton
                    onClick={() => {
                      if (!isPremium || isPaid) {
                        onConfigure(model.name);
                      } else {
                        openPaymentModal(model.name);
                      }
                    }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            </motion.div>
          );
        })}
      </List>
    </>
  );

  return (
    <>
  {/* Mobile Drawer */}
{/* Mobile Drawer */}
{isDrawerVisible && (
  <Box sx={{ display: { xs: "block", md: "none" } }}>
  <IconButton color="inherit" onClick={toggleDrawer} sx={{ ml: 2 }}>
    <TuneIcon />
    <Typography
      variant="body1"
      sx={{
        ml: 1,
        border: "1px solid #C5CAE9",
        borderRadius: 10,
        px: 2,
        bgcolor: "#E8EAF6",
        color: "#3F51B5",
        fontWeight: "bold",
        "&:hover": { bgcolor: "#D3D3D3" },
      }}
    >
      Configure Models
    </Typography>
  </IconButton>
  <Drawer
    anchor="left"
    open={mobileOpen}
    onClose={() => {
      toggleDrawer();
      setIsDrawerVisible(false); // Hide the drawer completely on close
    }}
    sx={{
      "& .MuiDrawer-paper": {
        width: mobileOpen ? 300 : 0, // Width transition
        transform: mobileOpen ? "translateX(0)" : "translateX(-100%)", // Transform transition
        boxSizing: "border-box",
        bgcolor: "#283593",
        color: "#E8EAF6",
        transition: "width 1.5s ease, transform 1.5s ease", // Ensure smooth transition for both width and transform
      },
    }}

  >
    {drawerContent}
  </Drawer>
</Box>



)}


        {/* Permanent Desktop Drawer */}
        {isDrawerVisible && (
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          display: { xs: "none", md: "block" },
          width: 300,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
            bgcolor: "#283593",
            color: "#E8EAF6",
          },
        }}
      >
        {drawerContent}
      </Drawer>
        )}

      {/* Payment Modal */}
      <StripePaymentModal
        open={isPaymentModalOpen}
        onClose={closePaymentModal}
        modelName={modelName}
        userId={user?.id}
      />


    </>
  );
};

export default ModelDrawer;
