import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";



const PrivateRoute = ({ element: Component }) => {
    const {isAuthenticated}=useAuth()
  return isAuthenticated ? Component : <Navigate to="/" />;
};

export default PrivateRoute;
