import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  // CircularProgress,
} from "@mui/material";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutForm = ({ modelName, userId, onClose, setError, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const cardElement = elements.getElement(CardElement);

      // Create payment intent
      const { error: backendError, clientSecret } = await fetch(
        `${process.env.REACT_APP_API_URL}/paywall/create-payment-intent`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ model: modelName, user_id: userId }),
        }
      ).then((res) => res.json());

      if (backendError) throw new Error(backendError);

      // Confirm payment with Stripe
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (stripeError) throw new Error(stripeError.message);

      // Display the success message
      setSuccessMessage(`Transaction ID: ${paymentIntent.id}`);
      // show the success message for 3 seconds before redirecting
      // Call the onPaymentSuccess callback
      // setTimeout(() => {
      //   navigate("/chat-prompt", { replace: true });
      // }, 3000); // 3 seconds delay
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

return (
<>
  <form onSubmit={handleSubmit}>
    <Typography variant="body1" sx={{ mb: 2 }}>
      Complete payment to access Model: {modelName}.
    </Typography>
    <CardElement options={{ style: { base: { fontSize: "16px" } } }} />
    <DialogActions sx={{ mt: 2 }}>
      <Button onClick={onClose} color="secondary" disabled={loading}>
        Cancel
      </Button>
      <Button type="submit" color="primary" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Pay Now"}
      </Button>
    </DialogActions>
  </form>

  {/* Success Dialog */}
  <Dialog open={!!successMessage} onClose={() => setSuccessMessage(null)}>
    <DialogTitle>Payment Successful</DialogTitle>
    <DialogContent>
      <Typography variant="body1" gutterBottom>
        Thank you for your purchase! Your transaction was successful.
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {successMessage}
      </Typography>
    </DialogContent>
    <DialogActions>
    <Button
  onClick={() => {
    setSuccessMessage(null); // Close the dialog
    navigate("/chat-prompt", { replace: true }); // Navigate to the ChatPrompt page
    window.location.reload(); // Reload the page
  }}
  color="primary"
  variant="contained"
>
  Continue
</Button>
    </DialogActions>
  </Dialog>
</>


  );
};

const StripePaymentModal = ({ open, onClose, modelName, userId, onPaymentSuccess }) => {
  const [error, setError] = useState("");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Complete Payment</DialogTitle>
      <DialogContent>
        {error && (
          <Typography variant="body2" color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        <Elements stripe={stripePromise}>
          <CheckoutForm
            modelName={modelName}
            userId={userId}
            onClose={onClose}
            setError={setError}
            onPaymentSuccess={onPaymentSuccess}
          />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

export default StripePaymentModal;
