import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Tooltip,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ChatIcon from '@mui/icons-material/Chat';
import SaveIcon from '@mui/icons-material/Save';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useAuth } from '../contexts/AuthContext';
import { useLocation, useNavigate } from "react-router-dom";

const HeaderActions = ({ handleClearChat, saveChatHistory }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = Boolean(anchorEl);
    const location = useLocation(); // Get the current route

  // Simulated auth context
  const { user, isAuthenticated, logout } = useAuth()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(90deg, #3f51b5, #5a69d4)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        padding: '5px 20px',
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* Logo Section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            cursor: 'pointer',
          }}
          onClick={() => navigate('/')}
        >
          <ChatIcon sx={{ fontSize: 30, color: '#fff' }} />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: '#fff',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
            }}
          >
            AI-CHAT JAM
          </Typography>
          {user?.premium && (
            <Tooltip title="Premium Member" arrow>
              <WorkspacePremiumIcon
                sx={{
                  fontSize: 24,
                  color: '#FFD700',
                  ml: 1,
                  animation: 'scaleUp 1.2s infinite alternate',
                }}
              />
            </Tooltip>
          )}
        </Box>

       {/* Desktop Actions */}
       <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          {isAuthenticated ? (
            <>
              {/* Show Profile Button if not on /profile */}
              {location.pathname !== '/profile' && (
                <Button
                  startIcon={<ChatIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: '#3f51b5',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#303f9f',
                    },
                  }}
                  onClick={() => navigate('/profile')}
                >
                  Profile
                </Button>
              )}
              <Button
                startIcon={<LogoutIcon />}
                variant="contained"
                sx={{
                  backgroundColor: '#ff5252',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
                onClick={logout}
              >
                Logout
              </Button>
            </>
          ) : (
    <Button
      startIcon={<LoginIcon />}
      variant="contained"
      sx={{
        backgroundColor: '#00bcd4',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#0097a7',
        },
      }}
      onClick={() => navigate('/enter')}
    >
      Login
    </Button>
  )}
  {handleClearChat && (
    <Button
      startIcon={<ClearAllIcon />}
      variant="contained"
      sx={{
        backgroundColor: '#ff9800',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#f57c00',
        },
      }}
      onClick={handleClearChat}
    >
      Clear Chat
    </Button>
  )}
  {saveChatHistory && (
    <Button
      startIcon={<SaveIcon />}
      variant="contained"
      sx={{
        backgroundColor: '#4caf50',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#388e3c',
        },
      }}
      onClick={saveChatHistory}
    >
      Save Chat
    </Button>
  )}
  {saveChatHistory ? (
    <Button
      startIcon={<ChatIcon />}
      variant="contained"
      sx={{
        backgroundColor: '#3f51b5',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#303f9f',
        },
      }}
      onClick={() => navigate('/chat-history')}
    >
      Chat History
    </Button>
  ) : (
    <Button
      startIcon={<ChatIcon />}
      variant="contained"
      sx={{
        backgroundColor: '#3f51b5',
        fontWeight: 'bold',
        '&:hover': {
          backgroundColor: '#303f9f',
        },
      }}
      onClick={() => navigate(-1)}
    >
      Conversation
    </Button>
  )}
</Box>


        {/* Mobile Actions */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton color="inherit" onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isMobile}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                width: '200px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
              },
            }}
          >
            {isAuthenticated ? (
              <MenuItem onClick={logout}>
                <LogoutIcon sx={{ mr: 1 }} /> Logout
              </MenuItem>
            ) : (
              <MenuItem onClick={() => navigate('/enter')}>
                <LoginIcon sx={{ mr: 1 }} /> Login
              </MenuItem>
            )}
            <MenuItem onClick={handleClearChat}>
              <ClearAllIcon sx={{ mr: 1 }} /> Clear Chat
            </MenuItem>
            <MenuItem onClick={saveChatHistory}>
              <SaveIcon sx={{ mr: 1 }} /> Save Chat
            </MenuItem>
            <MenuItem onClick={() => navigate('/chat-history')}>
              <ChatIcon sx={{ mr: 1 }} /> Chat History
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderActions;
