import React, { createContext, useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { isLoggedIn, loginUser, logoutUser } from "../api/api";
import { decryptData, encryptData } from "../utils/Security";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("ai9#fkLsR");

    if (storedToken && storedUser) {
      const usr = decryptData(storedUser);
      if (usr && usr.email) {
        isLoggedIn(usr.email)
          .then((res) => {
            if (res) {
              localStorage.setItem("token", usr.token);
              localStorage.setItem("ai9#fkLsR", encryptData(usr));
              setIsAuthenticated(true);
              setUser(usr);
            } else {
              localStorage.removeItem("token");
              localStorage.removeItem("ai9#fkLsR");
              setIsAuthenticated(false);
              setUser(null);
            }
          })
          .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("ai9#fkLsR");
            setIsAuthenticated(false);
            setUser(null);
          })
          .finally(() => setLoading(false));
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  const login = async ({ email, password }) => {
    try {
      const userData = await loginUser(email, password);
      setIsAuthenticated(true);
      setUser(userData);

      localStorage.setItem("token", userData.token);
      localStorage.setItem("ai9#fkLsR", encryptData(userData));
    } catch (error) {
      console.error("Login failed:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const response = await logoutUser();
      localStorage.removeItem("ai9#fkLsR");
      setIsAuthenticated(false);
      setUser(null);
      window.location.href = "/login";
    } catch (error) {
      console.error("Error logging out:", error.response?.data);
      throw error;
    }
  }


  if (loading) {
    return <CircularProgress size={50} sx={{ position: "absolute", top: "50%", left: "50%" }} />;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, setIsAuthenticated, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);

export default AuthProvider;
